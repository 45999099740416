import React from 'react';

import StatusDisplay from '../../components/StatusDisplay';

import {
  ORDER_READY_STATUS,
  ORDER_READY_STATUS_MAPPING,
  ORDER_READY_STATUS_TOOLTIP
} from '../../constants/orderReadyConstants';
import { colors } from '../../palette';
import {
  UpdateOutlined,
  RateReviewOutlined,
  LocalShippingOutlined,
  CheckOutlined,
  PlaylistAddCheck,
  PlayCircleFilledWhiteOutlined,
} from "@mui/icons-material";

function OrderReadyStatusDisplay(props) {
  const { status, onClick, onAcceptedOrderPage = false } = props;
  const statusStr = ORDER_READY_STATUS_MAPPING[status];
  let bgColor;
  let textColor = 'black';
  let toolTip = ORDER_READY_STATUS_TOOLTIP[status];
  let icon;
  switch (status) {
    case ORDER_READY_STATUS.NOT_STARTED:
      icon = <PlayCircleFilledWhiteOutlined />
      break;
    case ORDER_READY_STATUS.NEED_UPDATE:
      textColor = 'white';
      bgColor = colors.bgOrderNeedUpdate;
      icon = <UpdateOutlined style={{ color: 'white' }} />
      break;
    case ORDER_READY_STATUS.SUBMITTED:
      bgColor = colors.bgSubmittedStatus;
      icon = <RateReviewOutlined />;
      break;
    case ORDER_READY_STATUS.VERIFIED:
      bgColor = colors.bgOrderVerified;
      textColor = 'white';
      icon = <PlaylistAddCheck style={{ color: 'white' }} />;
      break;
    case ORDER_READY_STATUS.READY_TO_SHIP:
      bgColor = colors.bgORderReadyToShip
      textColor = 'white';
      icon = <LocalShippingOutlined style={{ color: 'white' }} />;
      break;
    case ORDER_READY_STATUS.SHIPPED:
      bgColor = colors.bgOrderShipped;
      textColor = 'white';
      icon = <CheckOutlined style={{ color: 'white' }} />;
      break;
    default:
      break;
  }
  return (
    <div onClick={onClick}>
      <StatusDisplay
        label={statusStr}
        bgColor={bgColor}
        textColor={textColor}
        toolTip={toolTip}
        icon={icon}
        onAcceptedOrderPage={onAcceptedOrderPage}
      />
    </div>
  );
}

export default OrderReadyStatusDisplay;
