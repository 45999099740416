export const FACTOREM_BLUE = '#1272E3';

export const colors = {
  background: 'linear-gradient(90deg, #E6F9FF, #5496EB)',
  mobileBackground: 'linear-gradient(#E6F9FF, #5496EB)',
  errorRed: '#DC143C',
  buttonBlue: '#288BFD',
  titleBlue: '#0C5DBC',
  inputBorderBlue: '#62B4FF',
  inputLabelGrey: '#C4C4C4',
  inputBorderGrey: '#C4C4C4',
  fontWhite: '#FFFFFF',
  fontBlack: '#000000',
  fontGrey: '#515151',
  fontLightGrey: '#9D9D9D',
  statusLightBlue: '#95CCFF',
  boxShadowBlue: '#58cdff',
  buttonColorBlueGradient:
    'linear-gradient(231.54deg, #62B4FF 19.53%, #60A0EA 84.32%)',
  dragdropBg: 'rgba(98, 180, 255, 0.06)',
  uploadFileBg: '#F6FAFF',
  uploadFileBorder: '#60A0EA',
  uploadPOBg: '#E3FFDA',
  uploadPOBorder: '#4DA159',
  boxShadowFAQClicked:
    '0px 0px 15px rgba(89, 205, 255, 0.5), 4px 10px 20px rgba(0, 0, 0, 0.1)',
  colorDarkBlueGradient:
    'linear-gradient(90deg, rgba(96,160,234,1) 0%, rgba(96,160,234,1) 100%)',
  darkBlueBorder: '1px solid rgba(96, 160, 234, 0.5)',
  expansionPanelBorder: '1px solid rgba(0, 0, 0, .125)',
  expansionBackgroundColor: 'rgba(0, 0, 0, .03)',
  factoremBlue: '#043578',
  blueSidebarBg: `linear-gradient(227.25deg, #60A0EA -15.96%, #0C5DBC 89.21%), #0C5DBC`,
  mainLightBlue: '#fdfdff',
  successLightGreen: '#dbffd2',
  pendingLightYellow: '#fffdca',
  paleBlue: '#ECF5FF',
  secondaryBlue: '#cbe2ff',
  tertiaryBlue: '#005DBC',
  bgLightGrey: '#F5F5F5',
  solidBlue: '#0059C1',
  fontDarkGrey: '#222326',
  successGreen: '#39C16C',
  fontBlueBlack: '#263238',
  darkGreyBorder: '1px solid #D8D8D8',
  quotedLabelBlue: '#9ACCF3',
  hotRed: '#CD0000',
  redGradient: 'linear-gradient(231.54deg, #dc3545 19.53%, #fc3282 84.32%)',
  greenGradient: 'linear-gradient(231.54deg, #28a745 19.53%, #66d147 84.32%)',
  colorBlueGradient:
    'linear-gradient(131deg, #FFFFFF 0%, #6CB7F0 0%, #0092FF 44%, #006CFF 71%, #004EFF 100%)',
  bgGrey: '#888888',
  fontBlackGrey: '#383838',
  fontFaintGrey: '#0000009A',
  bgBeige: '#FFE0BD',
  bgLightBeige: '#fef0e3',
  bgWhite: '#F9F9F9',
  fontMediumGray: '#B1B1B1',
  lightGray: '#D3D3D3',
  menuItemSelected: '#9ACCF3',
  menuItemHover: `rgba(154, 204, 243, 0.4)`,
  projectJob: '#1A67C1',
  confirmedProjectJob: '#4DA159',
  greenTagColor: '#62BA71',
  manualJob: '#B6D8FF',
  paleYellow: "#FFFCC9",
  warningYellow: "#ffaa33",
  orange: "#FE9050",
  bgSubmittedStatus: "#FFE662",
  bgOrderShipped: '#4caf50',
  bgOrderVerified: '#0c5dbc',
  bgORderReadyToShip: '#E39008',
  bgOrderNeedUpdate: '#ff0000',
  blue060: '#0C5DBC',
};
