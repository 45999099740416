export const ORDER_READY_STATUS = {
  NOT_STARTED: 'not_started',
  SUBMITTED: 'submitted',
  VERIFIED: 'verified',
  NEED_UPDATE: 'need_update',
  READY_TO_SHIP: 'ready_to_ship',
  SHIPPED: 'shipped',
}

export const ORDER_READY_STATUS_MAPPING = {
  [ORDER_READY_STATUS.NOT_STARTED]: 'Not started',
  [ORDER_READY_STATUS.SUBMITTED]: 'Pending review',
  [ORDER_READY_STATUS.VERIFIED]: 'Verified',
  [ORDER_READY_STATUS.NEED_UPDATE]: 'Update required',
  [ORDER_READY_STATUS.READY_TO_SHIP]: 'Ready for Delivery',
  [ORDER_READY_STATUS.SHIPPED]: 'Shipped',
}

export const ORDER_READY_STATUS_MAPPING_NUMBER = {
  [ORDER_READY_STATUS.NOT_STARTED]: 1,
  [ORDER_READY_STATUS.SUBMITTED]: 2,
  [ORDER_READY_STATUS.VERIFIED]: 4,
  [ORDER_READY_STATUS.NEED_UPDATE]: 3,
  [ORDER_READY_STATUS.READY_TO_SHIP]: 5,
  [ORDER_READY_STATUS.SHIPPED]: 6,
};

export const ORDER_READY_STATUS_TOOLTIP = {
  [ORDER_READY_STATUS.NOT_STARTED]: 'Upload photos/QC reports once order is ready',
  [ORDER_READY_STATUS.SUBMITTED]: 'Your submission is being reviewed for shipment',
  [ORDER_READY_STATUS.VERIFIED]: 'Verified for shipment, awaiting Factorem Delivery Order to proceed',
  [ORDER_READY_STATUS.NEED_UPDATE]: 'Review comments to provide updates required',
  [ORDER_READY_STATUS.READY_TO_SHIP]: 'Download Factorem Delivery Order and proceed to shipment',
  [ORDER_READY_STATUS.SHIPPED]: 'Your order has been shipped',
}

export const ORDER_READY_RESOURCE_TYPES = {
  ISOMETRIC_IMAGE: 'ISOMETRIC_IMAGE',
  FRONT_IMAGE: 'FRONT_IMAGE',
  BACK_IMAGE: 'BACK_IMAGE',
  LEFT_IMAGE: 'LEFT_IMAGE',
  RIGHT_IMAGE: 'RIGHT_IMAGE',
  QC_REPORT: 'QC_REPORT',
  DELIVERY_ORDER: 'DELIVERY_ORDER',
  WAYBILL: 'WAYBILL',
}

export const ORDER_READY_IMAGE_RESOURCE_TYPES = [
  ORDER_READY_RESOURCE_TYPES.FRONT_IMAGE,
  ORDER_READY_RESOURCE_TYPES.LEFT_IMAGE,
  ORDER_READY_RESOURCE_TYPES.RIGHT_IMAGE,
]

export const ORDER_READY_REVIEW_COMMENTS = {
  UPDATE_QC_REPORT: 'Need update QC Report',
  UPDATE_PART_IMAGES: 'Need update part images',
  REWORK: 'Need rework',
}

export const SHIPMENT_PROVIDERS = [
  'DHL Express',
  'FedEx',
  'UPS',
  'Singapore Post (SingPost)',
  'TNT',
  'Aramex',
  'Ninja Van',
  'J&T Express',
  'SF Express',
  'Qxpress',
  'Other',
]
