import React, { Fragment } from 'react';

import WhiteButton from '../buttons/WhiteButton';
import BlueButton from '../buttons/BlueButton';

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import Title from '../Title';

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: 600,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
}));

function YesNoPopup(props) {
  const { classes } = useStyles();
  const {
    open,
    handleNo,
    handleYes,
    title = "Are you sure that you want to delete this file?",
    body,
    noButtonLabel = 'No',
    yesButtonLabel = 'Yes',
  } = props;

  const renderBody = () => {
    if (typeof body === 'string') {
      return (
        <Typography>{body}</Typography>
      );
    }
    return body;
  }

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={handleNo}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <Title contentTitle={title} size="small" />
      </DialogTitle>
      <div
        style={{
          paddingBottom: '1rem',
        }}
      >
        <DialogContent
          style={{
            padding: '0 30px',
            paddingBottom: '2rem',
            textAlign: 'center',
          }}
        >
          <Fragment>
            {renderBody()}
          </Fragment>
        </DialogContent>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <WhiteButton
            onBtnClick={handleNo}
            btnContent={noButtonLabel}
            size='small'
          />
          <Box className={classes.space}></Box>
          <BlueButton
            onBtnClick={() => {
              handleYes();
              handleNo();
            }}
            btnContent={yesButtonLabel}
          />
        </div>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={handleNo}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default YesNoPopup;
