import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { getAllProjectOrderReady } from '../apis/orderReadyApi';

function DeliveryStep(props) {
  const { user } = props;

  const { data: allProjectOrderReady = [] } = useQuery('getAllProjectOrderReady', getAllProjectOrderReady);

  const [userID, setUserID] = useState(null);
  const [projectID, setProjectID] = useState(null);

  useEffect(() => {
    if (isEmpty(user)) {
      return;
    }
    setUserID(user.userID);
  }, [user]);

  useEffect(() => {
    if (isEmpty(user) || isEmpty(allProjectOrderReady)) {
      return;
    }
    const orderReady = allProjectOrderReady.find(or => or.supplierID === user.userID);
    if (orderReady) {
      setProjectID(orderReady.projectID);
    }
  }, [user, allProjectOrderReady]);

  return projectID && (
    <div>
      View Order Ready Management&nbsp;
      <Link
        to={`/projects/${projectID}/order-ready?supplierID=${userID}`}
      >
        here
      </Link>
    </div>
  );
}

export default DeliveryStep;
